import { LanguageCode } from './Interfaces';

export const LanguageFlag = ({ language }: { language: LanguageCode }): JSX.Element => {
  // Extract the base language code (e.g., 'en' from 'en-US')
  const baseCode = language.split('-')[0].toLowerCase();

  switch (baseCode) {
    case 'en':
      return <span>🇺🇸 English</span>;
    case 'de':
      return <span>🇩🇪 German</span>;
    case 'tr':
      return <span>🇹🇷 Turkish</span>;
    case 'es':
      return <span>🇪🇸 Spanish</span>;
    case 'fr':
      return <span>🇫🇷 French</span>;
    case 'it':
      return <span>🇮🇹 Italian</span>;
    case 'pt':
      return <span>🇵🇹 Portuguese</span>;
    case 'ru':
      return <span>🇷🇺 Russian</span>;
    case 'zh':
      return <span>🇨🇳 Chinese</span>;
    case 'ja':
      return <span>🇯🇵 Japanese</span>;
    case 'ko':
      return <span>🇰🇷 Korean</span>;
    case 'ar':
      return <span>🇸🇦 Arabic</span>;
    case 'hi':
      return <span>🇮🇳 Hindi</span>;
    case 'nl':
      return <span>🇳🇱 Dutch</span>;
    case 'pl':
      return <span>🇵🇱 Polish</span>;
    case 'sv':
      return <span>🇸🇪 Swedish</span>;
    case 'da':
      return <span>🇩🇰 Danish</span>;
    case 'fi':
      return <span>🇫🇮 Finnish</span>;
    case 'el':
      return <span>🇬🇷 Greek</span>;
    case 'he':
      return <span>🇮🇱 Hebrew</span>;
    case 'id':
      return <span>🇮🇩 Indonesian</span>;
    case 'ms':
      return <span>🇲🇾 Malay</span>;
    case 'th':
      return <span>🇹🇭 Thai</span>;
    case 'vi':
      return <span>🇻🇳 Vietnamese</span>;
    case 'cs':
      return <span>🇨🇿 Czech</span>;
    case 'ro':
      return <span>🇷🇴 Romanian</span>;
    case 'hu':
      return <span>🇭🇺 Hungarian</span>;
    case 'uk':
      return <span>🇺🇦 Ukrainian</span>;
    case 'hr':
      return <span>🇭🇷 Croatian</span>;
    case 'ca':
      return <span>🇪🇸 Catalan</span>;
    case 'fa':
      return <span>🇮🇷 Persian</span>;
    case 'sr':
      return <span>🇷🇸 Serbian</span>;
    case 'sk':
      return <span>🇸🇰 Slovak</span>;
    case 'bn':
      return <span>🇧🇩 Bengali</span>;
    case 'ta':
      return <span>🇮🇳 Tamil</span>;
    case 'te':
      return <span>🇮🇳 Telugu</span>;
    case 'mr':
      return <span>🇮🇳 Marathi</span>;
    case 'ur':
      return <span>🇵🇰 Urdu</span>;
    case 'gu':
      return <span>🇮🇳 Gujarati</span>;
    case 'kn':
      return <span>🇮🇳 Kannada</span>;
    case 'ml':
      return <span>🇮🇳 Malayalam</span>;
    case 'si':
      return <span>🇱🇰 Sinhala</span>;
    case 'my':
      return <span>🇲🇲 Burmese</span>;
    case 'km':
      return <span>🇰🇭 Khmer</span>;
    case 'lo':
      return <span>🇱🇦 Lao</span>;
    case 'ne':
      return <span>🇳🇵 Nepali</span>;
    case 'mn':
      return <span>🇲🇳 Mongolian</span>;
    case 'ka':
      return <span>🇬🇪 Georgian</span>;
    case 'am':
      return <span>🇪🇹 Amharic</span>;
    case 'sw':
      return <span>🇹🇿 Swahili</span>;
    case 'zu':
      return <span>🇿🇦 Zulu</span>;
    case 'af':
      return <span>🇿🇦 Afrikaans</span>;
    case 'az':
      return <span>🇦🇿 Azerbaijani</span>;
    case 'eu':
      return <span>🇪🇸 Basque</span>;
    case 'be':
      return <span>🇧🇾 Belarusian</span>;
    case 'bs':
      return <span>🇧🇦 Bosnian</span>;
    case 'bg':
      return <span>🇧🇬 Bulgarian</span>;
    case 'cy':
      return <span>🇬🇧 Welsh</span>;
    case 'et':
      return <span>🇪🇪 Estonian</span>;
    case 'ga':
      return <span>🇮🇪 Irish</span>;
    case 'gl':
      return <span>🇪🇸 Galician</span>;
    case 'ha':
      return <span>🇳🇬 Hausa</span>;
    case 'haw':
      return <span>🇺🇸 Hawaiian</span>;
    case 'is':
      return <span>🇮🇸 Icelandic</span>;
    case 'ig':
      return <span>🇳🇬 Igbo</span>;
    case 'yi':
      return <span>🇮🇱 Yiddish</span>;
    case 'jv':
      return <span>🇮🇩 Javanese</span>;
    case 'kk':
      return <span>🇰🇿 Kazakh</span>;
    case 'ku':
      return <span>🇹🇷 Kurdish</span>;
    case 'ky':
      return <span>🇰🇬 Kyrgyz</span>;
    case 'lv':
      return <span>🇱🇻 Latvian</span>;
    case 'lt':
      return <span>🇱🇹 Lithuanian</span>;
    case 'lb':
      return <span>🇱🇺 Luxembourgish</span>;
    case 'mk':
      return <span>🇲🇰 Macedonian</span>;
    case 'mg':
      return <span>🇲🇬 Malagasy</span>;
    case 'mt':
      return <span>🇲🇹 Maltese</span>;
    case 'mi':
      return <span>🇳🇿 Maori</span>;
    case 'mrj':
    case 'mhr':
      return <span>🇷🇺 Mari</span>;
    case 'no':
      return <span>🇳🇴 Norwegian</span>;
    case 'or':
      return <span>🇮🇳 Oriya</span>;
    case 'ps':
      return <span>🇦🇫 Pashto</span>;
    case 'pa':
      return <span>🇮🇳 Punjabi</span>;
    case 'qu':
      return <span>🇵🇪 Quechua</span>;
    case 'rw':
      return <span>🇷🇼 Kinyarwanda</span>;
    case 'sa':
      return <span>🇮🇳 Sanskrit</span>;
    case 'sc':
      return <span>🇮🇹 Sardinian</span>;
    case 'sn':
      return <span>🇿🇼 Shona</span>;
    case 'sd':
      return <span>🇵🇰 Sindhi</span>;
    case 'so':
      return <span>🇸🇴 Somali</span>;
    case 'su':
      return <span>🇮🇩 Sundanese</span>;
    case 'tg':
      return <span>🇹🇯 Tajik</span>;
    case 'tt':
      return <span>🇷🇺 Tatar</span>;
    case 'ti':
      return <span>🇪🇷 Tigrinya</span>;
    case 'tk':
      return <span>🇹🇲 Turkmen</span>;
    case 'udm':
      return <span>🇷🇺 Udmurt</span>;
    case 'ug':
      return <span>🇨🇳 Uyghur</span>;
    case 'uz':
      return <span>🇺🇿 Uzbek</span>;
    case 'wa':
      return <span>🇧🇪 Walloon</span>;
    case 'xh':
      return <span>🇿🇦 Xhosa</span>;
    case 'yo':
      return <span>🇳🇬 Yoruba</span>;
    case 'za':
      return <span>🇨🇳 Zhuang</span>;
    default:
      return <span>{language}</span>;
  }
};

// Move languageOptions to a new file
