import { AppShell, Container } from '@mantine/core';
import SendridEmailTemplateSettings from './components/emailtemplate/SendgridTemplateSettings';

export default function EmailTemplateSettingsPage(): JSX.Element {
  return (
    <AppShell>
      <Container size="xl" py="md">
        <SendridEmailTemplateSettings />
      </Container>
    </AppShell>
  );
}
