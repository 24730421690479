import {
  Button,
  Card,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { ConceptMap } from '@medplum/fhirtypes';
import { Container, Form, useMedplum } from '@medplum/react';
import { useCallback, useEffect, useState } from 'react';

interface SendgridVariables {
  firstName: string;
  lastName: string;
  resetPasswordUrl?: string;
  projectName?: string;
  inviterName?: string;
  invitationUrl?: string;
}

interface TemplateConfig {
  templateId: string;
  description?: string;
}

interface EmailTemplateSettings {
  sendgridEmail: string;
  apiKey: string;
  inviteTemplate: TemplateConfig;
  resetPasswordTemplate: TemplateConfig;
}

export default function SendgridTemplateSettings(): JSX.Element {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [existingConceptMapId, setExistingConceptMapId] = useState<string>();
  const [settings, setSettings] = useState<EmailTemplateSettings>({
    sendgridEmail: '',
    apiKey: '',
    inviteTemplate: { templateId: '' },
    resetPasswordTemplate: { templateId: '' },
  });

  const isAuthenticated = !!medplum.getProfile();

  useEffect(() => {
    if (!isAuthenticated) {
      return undefined;
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, isAuthenticated]);

  const inviteVariables: SendgridVariables = {
    firstName: '',
    lastName: '',
    projectName: '',
    inviterName: '',
    invitationUrl: '',
  };

  const resetPasswordVariables: SendgridVariables = {
    firstName: '',
    lastName: '',
    resetPasswordUrl: '',
  };

  const validateForm = useCallback((data: EmailTemplateSettings) => {
    return !!(
      data.sendgridEmail &&
      data.apiKey &&
      data.inviteTemplate.templateId &&
      data.resetPasswordTemplate.templateId
    );
  }, []);

  const loadSettings = useCallback(async (): Promise<void> => {
    try {
      const [searchResult, project] = await Promise.all([
        medplum.searchResources(
          'ConceptMap',
          'identifier=https://api.ovok.com/fhir/StructureDefinition/email-delivery-provider|sendgrid'
        ),
        medplum.readResource('Project', medplum.getProject()?.id as string),
      ]);

      const apiKey = project.secret?.find((s) => s.name === 'SENDGRID_API_KEY')?.valueString || '';

      if (searchResult.length > 0) {
        const conceptMap = searchResult[0];
        setExistingConceptMapId(conceptMap.id);

        const emailGroup = conceptMap.group?.find(
          (g) => g.source === 'https://api.ovok.com/fhir/StructureDefinition/email-accounts'
        );
        const templateGroup = conceptMap.group?.find(
          (g) => g.source === 'https://api.ovok.com/fhir/StructureDefinition/email-template-codes'
        );

        const newSettings = {
          sendgridEmail: emailGroup?.element?.[0]?.target?.[0]?.code || '',
          apiKey,
          inviteTemplate: {
            templateId: templateGroup?.element?.find((e) => e.code === 'INVITE_TO_PROJECT')?.target?.[0]?.code || '',
            description: templateGroup?.element?.find((e) => e.code === 'INVITE_TO_PROJECT')?.target?.[0]?.comment,
          },
          resetPasswordTemplate: {
            templateId: templateGroup?.element?.find((e) => e.code === 'RESET_PASSWORD')?.target?.[0]?.code || '',
            description: templateGroup?.element?.find((e) => e.code === 'RESET_PASSWORD')?.target?.[0]?.comment,
          },
        };

        setSettings(newSettings);
        setIsValid(validateForm(newSettings));
      }
    } catch (_error) {
      showNotification({
        color: 'red',
        message: 'Error loading settings',
      });
    } finally {
      setLoading(false);
    }
  }, [medplum, validateForm]);

  useEffect(() => {
    loadSettings().catch((error) => {
      console.error('Failed to load settings:', error);
    });
  }, [loadSettings]);

  const handleFieldChange = (
    field:
      | keyof EmailTemplateSettings
      | 'inviteTemplateId'
      | 'inviteDescription'
      | 'resetPasswordTemplateId'
      | 'resetPasswordDescription',
    value: string
  ): void => {
    const newSettings = { ...settings };

    if (field === 'sendgridEmail' || field === 'apiKey') {
      newSettings[field] = value;
    } else if (field === 'inviteTemplateId') {
      newSettings.inviteTemplate.templateId = value;
    } else if (field === 'inviteDescription') {
      newSettings.inviteTemplate.description = value;
    } else if (field === 'resetPasswordTemplateId') {
      newSettings.resetPasswordTemplate.templateId = value;
    } else if (field === 'resetPasswordDescription') {
      newSettings.resetPasswordTemplate.description = value;
    }

    setSettings(newSettings);
    setIsValid(validateForm(newSettings));
    setIsDirty(true);
  };

  const handleSubmit = async (formData: any): Promise<void> => {
    try {
      const conceptMap: ConceptMap = {
        resourceType: 'ConceptMap',
        status: 'active',
        identifier: {
          system: 'https://api.ovok.com/fhir/StructureDefinition/email-delivery-provider',
          value: 'sendgrid',
        },
        group: [
          {
            source: 'https://api.ovok.com/fhir/StructureDefinition/email-accounts',
            element: [
              {
                code: 'EMAIL_FROM',
                target: [
                  {
                    code: formData.sendgridEmail,
                    equivalence: 'equivalent',
                    comment: 'Must be a valid email address registered with Sendgrid',
                  },
                ],
              },
            ],
          },
          {
            source: 'https://api.ovok.com/fhir/StructureDefinition/email-template-codes',
            target: 'https://sendgrid.com/dynamic-template',
            element: [
              {
                code: 'INVITE_TO_PROJECT',
                display: 'Invite Email',
                target: [
                  {
                    code: formData.inviteTemplateId,
                    equivalence: 'equivalent',
                    comment: formData.inviteDescription || '',
                  },
                ],
              },
              {
                code: 'RESET_PASSWORD',
                display: 'Reset Password Email',
                target: [
                  {
                    code: formData.resetPasswordTemplateId,
                    equivalence: 'equivalent',
                    comment: formData.resetPasswordDescription || '',
                  },
                ],
              },
            ],
          },
        ],
      };

      const project = await medplum.readResource('Project', medplum.getProject()?.id as string);
      const secrets = project.secret || [];
      const apiKeyIndex = secrets.findIndex((s) => s.name === 'SENDGRID_API_KEY');

      if (apiKeyIndex >= 0) {
        secrets[apiKeyIndex].valueString = formData.apiKey;
      } else {
        secrets.push({
          name: 'SENDGRID_API_KEY',
          valueString: formData.apiKey,
        });
      }

      if (existingConceptMapId) {
        await medplum.updateResource({ ...conceptMap, id: existingConceptMapId });
      } else {
        await medplum.createResource(conceptMap);
      }

      await medplum.updateResource({ ...project, resourceType: 'Project', secret: secrets });

      setIsDirty(false);
      showNotification({
        color: 'green',
        message: 'Sendgrid settings saved successfully.',
      });
    } catch (_error) {
      showNotification({
        color: 'red',
        message: 'Error saving Sendgrid settings.',
      });
    }
  };

  return (
    <Container fluid p="md">
      <Paper shadow="sm" p="xl" radius="md" withBorder pb="xl">
        <Form onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <Stack gap="xl">
            <Group justify="space-between" align="center">
              <Title order={2}>Email Templates Settings</Title>
              <Button
                type="submit"
                disabled={!isValid || !isDirty || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(settings.sendgridEmail)}
              >
                Save Changes
              </Button>
            </Group>
            <Text>Configure your Sendgrid email templates.</Text>

            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  name="sendgridEmail"
                  label="Sendgrid Account Email"
                  description="Your Sendgrid 'From' Email Address"
                  required
                  error={
                    settings.sendgridEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(settings.sendgridEmail)
                      ? 'Please enter a valid email address'
                      : undefined
                  }
                  value={settings.sendgridEmail}
                  onChange={(e) => handleFieldChange('sendgridEmail', e.currentTarget.value)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <PasswordInput
                  name="apiKey"
                  label="API Key"
                  required
                  description="Your Sendgrid API key"
                  value={settings.apiKey}
                  onChange={(e) => handleFieldChange('apiKey', e.currentTarget.value)}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={6}>
                <Card withBorder shadow="sm" p="md" h="100%" radius="md">
                  <Stack gap="md">
                    <Title order={3}>Invite Template</Title>
                    <TextInput
                      name="inviteTemplateId"
                      label="Template ID"
                      required
                      value={settings.inviteTemplate.templateId}
                      onChange={(e) => handleFieldChange('inviteTemplateId', e.currentTarget.value)}
                    />
                    <Text size="sm" c="dimmed">
                      Required Variables:
                    </Text>
                    <Text size="sm" style={{ whiteSpace: 'pre-line' }}>
                      {Object.keys(inviteVariables).join('\n')}
                    </Text>
                    <Textarea
                      name="inviteDescription"
                      label="Description"
                      value={settings.inviteTemplate.description}
                      onChange={(e) => handleFieldChange('inviteDescription', e.currentTarget.value)}
                      minRows={3}
                    />
                  </Stack>
                </Card>
              </Grid.Col>

              <Grid.Col span={6}>
                <Card withBorder shadow="sm" p="md" h="100%" radius="md">
                  <Stack gap="md">
                    <Title order={3}>Reset Password Template</Title>
                    <TextInput
                      name="resetPasswordTemplateId"
                      label="Template ID"
                      required
                      value={settings.resetPasswordTemplate.templateId}
                      onChange={(e) => handleFieldChange('resetPasswordTemplateId', e.currentTarget.value)}
                    />
                    <Text size="sm" c="dimmed">
                      Required Variables:
                    </Text>
                    <Text size="sm" style={{ whiteSpace: 'pre-line' }}>
                      {Object.keys(resetPasswordVariables).join('\n')}
                    </Text>
                    <Textarea
                      name="resetPasswordDescription"
                      label="Description"
                      value={settings.resetPasswordTemplate.description}
                      onChange={(e) => handleFieldChange('resetPasswordDescription', e.currentTarget.value)}
                      minRows={3}
                    />
                  </Stack>
                </Card>
              </Grid.Col>
            </Grid>
          </Stack>
        </Form>
      </Paper>
    </Container>
  );
}
