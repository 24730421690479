import { MedplumClient } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../config';

export interface OvokRequestConfig {
  params?: Record<string, string>;
  headers?: Record<string, string>;
}

export interface OvokResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
}

export class OvokClient {
  private baseUrl: string;
  private medplum: MedplumClient;

  constructor(medplum: MedplumClient) {
    const baseUrl = getConfig().ovokBaseUrl;
    if (!baseUrl) {
      throw new Error('OVOK base URL is not configured');
    }
    this.baseUrl = baseUrl;
    this.medplum = medplum;
  }

  needsRecreate(newMedplum: MedplumClient): boolean {
    return this.medplum !== newMedplum;
  }

  private async getHeaders(): Promise<Record<string, string>> {
    const token = await this.medplum.getAccessToken();
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  private async request<T>(
    method: string,
    path: string,
    body?: any,
    config: OvokRequestConfig = {}
  ): Promise<OvokResponse<T>> {
    const headers = await this.getHeaders();
    const url = new URL(path, this.baseUrl);

    if (config.params) {
      Object.entries(config.params).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
    }

    const response = await fetch(url.toString(), {
      method,
      headers: {
        ...headers,
        ...config.headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    // Check if response is empty (no content)
    if (response.status === 204 || response.status === 205) {
      return {
        data: null as T,
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries()),
      };
    }

    // Only try to parse JSON if there's content
    const contentType = response.headers.get('content-type');
    let data: T | null = null;

    if (contentType?.includes('application/json')) {
      const text = await response.text();
      data = text ? JSON.parse(text) : null;
    }

    return {
      data: data as T,
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers.entries()),
    };
  }

  async get<T>(path: string, config?: OvokRequestConfig): Promise<OvokResponse<T>> {
    return this.request<T>('GET', path, undefined, config);
  }

  async post<T>(path: string, body?: any, config?: OvokRequestConfig): Promise<OvokResponse<T>> {
    return this.request<T>('POST', path, body, config);
  }

  async put<T>(path: string, body?: any, config?: OvokRequestConfig): Promise<OvokResponse<T>> {
    return this.request<T>('PUT', path, body, config);
  }

  async patch<T>(path: string, body?: any, config?: OvokRequestConfig): Promise<OvokResponse<T>> {
    return this.request<T>('PATCH', path, body, config);
  }

  async delete<T>(path: string, config?: OvokRequestConfig): Promise<OvokResponse<T>> {
    return this.request<T>('DELETE', path, undefined, config);
  }
}

let ovokClientInstance: OvokClient | null = null;

export function useOvok(): OvokClient {
  const medplum = useMedplum();

  if (!ovokClientInstance) {
    ovokClientInstance = new OvokClient(medplum);
  } else if (ovokClientInstance.needsRecreate(medplum)) {
    ovokClientInstance = new OvokClient(medplum);
  }

  return ovokClientInstance;
}
