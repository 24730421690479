import {
  Group,
  Title,
  Button,
  Table,
  Menu,
  ActionIcon,
  Modal,
  Stack,
  Select,
  Loader,
  Paper,
  Container,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots, IconEdit, IconTrash } from '@tabler/icons-react';
import { useEffect, useState, useCallback } from 'react';
import { LanguageCode, LanguagesResponse, UpdateLanguagesRequest } from './Interfaces';
import { LanguageFlag } from './Flags';
import { languageOptions } from './LanguageOptions';
import { useOvok } from '../../utils/ovokclient';
import { showNotification } from '@mantine/notifications';
import classes from './TranslationTable.module.css';

interface LanguageTableProps {
  handleSelectLanguage: (language: LanguageCode) => void;
}

export const LanguageTable: React.FC<LanguageTableProps> = ({ handleSelectLanguage }): JSX.Element => {
  const ovok = useOvok();
  const [addLanguageModalOpened, { open: openAddLanguageModal, close: closeAddLanguageModal }] = useDisclosure(false);
  const [newLanguage, setNewLanguage] = useState('');
  const [languages, setLanguages] = useState<LanguageCode[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchLanguages = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await ovok.get<LanguagesResponse>('/localization/locales');
      if (response.status === 404) {
        const initialRequest: UpdateLanguagesRequest = {
          languages: ['en-US'],
          defaultLanguage: 'en-US',
        };
        await ovok.patch('/localization/locales', initialRequest);
        setLanguages(['en-US']);
      } else {
        setLanguages(response.data.languages);
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to fetch languages. Please try again.',
        autoClose: false,
      });
    } finally {
      setLoading(false);
    }
  }, [ovok]);

  useEffect(() => {
    fetchLanguages().catch((error) => {
      console.error('Error in fetchLanguages effect:', error);
    });
  }, [fetchLanguages]);

  const handleAddLanguage = async (): Promise<void> => {
    if (!newLanguage) {
      return;
    }

    setLoading(true);
    try {
      const updatedLanguages = [...languages, newLanguage as LanguageCode];
      const request: UpdateLanguagesRequest = {
        languages: updatedLanguages,
        defaultLanguage: 'en-US',
      };
      const response = await ovok.patch('/localization/locales', request);
      if (response.status === 200) {
        setLanguages(updatedLanguages);
        closeAddLanguageModal();
        setNewLanguage('');
        showNotification({
          color: 'green',
          title: 'Success',
          message:
            'Language added successfully. Please wait a few seconds to automatically translate the language using source values.',
        });
      } else {
        throw new Error('Failed to add language');
      }
    } catch (error) {
      console.error('Error adding language:', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to add language. Please try again.',
        autoClose: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLanguage = async (languageToDelete: LanguageCode): Promise<void> => {
    if (languageToDelete === 'en-US') {
      return;
    }

    setLoading(true);
    try {
      const updatedLanguages = languages.filter((lang) => lang !== languageToDelete);
      const request: UpdateLanguagesRequest = {
        languages: updatedLanguages,
        defaultLanguage: 'en-US',
      };
      const response = await ovok.patch('/localization/locales', request);
      if (response.status === 200) {
        setLanguages(updatedLanguages);
        showNotification({
          color: 'green',
          title: 'Success',
          message: 'Language disabled successfully.',
        });
      } else {
        throw new Error('Failed to disable language');
      }
    } catch (error) {
      console.error('Error disabling language:', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to disable language. Please try again.',
        autoClose: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid p="md">
      <Paper shadow="sm" p="lg" radius="md" withBorder pb="xl">
        <Group justify="space-between" mb="lg">
          <Title order={2}>Languages</Title>
          <Button onClick={openAddLanguageModal} loading={loading}>
            Add new language
          </Button>
        </Group>

        <Table className={classes.root}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th className={classes.header} style={{ width: '40%' }}>
                Language Name
              </Table.Th>
              <Table.Th className={classes.header} style={{ width: '40%' }}>
                Action
              </Table.Th>
              <Table.Th className={classes.header} style={{ width: '20%' }}></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td colSpan={3} style={{ textAlign: 'center', padding: '2rem' }}>
                  <Loader size="sm" />
                </Table.Td>
              </Table.Tr>
            ) : (
              languages.map((languageCode, index) => (
                <Table.Tr key={index} className={classes.row} onClick={() => handleSelectLanguage(languageCode)}>
                  <Table.Td className={classes.cell}>
                    <LanguageFlag language={languageCode} />
                  </Table.Td>
                  <Table.Td className={classes.cell}>
                    <span
                      style={{
                        color: languageCode === 'en-US' ? 'var(--mantine-color-blue-6)' : 'var(--mantine-color-gray-6)',
                      }}
                    >
                      {languageCode === 'en-US' ? 'Review now' : 'Translate'}
                    </span>
                  </Table.Td>
                  <Table.Td className={classes.cell}>
                    <Menu position="bottom-end">
                      <Menu.Target>
                        <ActionIcon onClick={(e) => e.stopPropagation()}>
                          <IconDots size={16} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => handleSelectLanguage(languageCode)}
                          leftSection={<IconEdit size={16} />}
                        >
                          Edit
                        </Menu.Item>
                        {!(languageCode === 'en-US') && (
                          <Menu.Item
                            leftSection={<IconTrash size={16} />}
                            color="red"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteLanguage(languageCode).catch((error) => {
                                console.error('Error in handleDeleteLanguage:', error);
                              });
                            }}
                          >
                            Disable
                          </Menu.Item>
                        )}
                      </Menu.Dropdown>
                    </Menu>
                  </Table.Td>
                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </Table>

        <Modal opened={addLanguageModalOpened} onClose={closeAddLanguageModal} title="Add New Language">
          <Stack>
            <Select
              label="Language"
              searchable
              placeholder="Select a language"
              data={languageOptions}
              value={newLanguage}
              onChange={(value) => setNewLanguage(value || '')}
              disabled={loading}
            />
            <Group justify="right" mt="md">
              <Button variant="subtle" onClick={closeAddLanguageModal} disabled={loading}>
                Cancel
              </Button>
              <Button disabled={loading || !newLanguage} onClick={handleAddLanguage} loading={loading}>
                Add Language
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Paper>
    </Container>
  );
};
