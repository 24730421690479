export const languageOptions = [
  { value: 'en-US', label: '🇺🇸 English' },
  { value: 'es-ES', label: '🇪🇸 Spanish' },
  { value: 'fr-FR', label: '🇫🇷 French' },
  { value: 'it-IT', label: '🇮🇹 Italian' },
  { value: 'de-DE', label: '🇩🇪 German' },
  { value: 'tr-TR', label: '🇹🇷 Turkish' },
  { value: 'pt-PT', label: '🇵🇹 Portuguese' },
  { value: 'ru-RU', label: '🇷🇺 Russian' },
  { value: 'zh-CN', label: '🇨🇳 Chinese' },
  { value: 'ja-JP', label: '🇯🇵 Japanese' },
  { value: 'ko-KR', label: '🇰🇷 Korean' },
  { value: 'ar-SA', label: '🇸🇦 Arabic' },
  { value: 'hi-IN', label: '🇮🇳 Hindi' },
  { value: 'nl-NL', label: '🇳🇱 Dutch' },
  { value: 'pl-PL', label: '🇵🇱 Polish' },
  { value: 'sv-SE', label: '🇸🇪 Swedish' },
  { value: 'da-DK', label: '🇩🇰 Danish' },
  { value: 'fi-FI', label: '🇫🇮 Finnish' },
  { value: 'el-GR', label: '🇬🇷 Greek' },
  { value: 'he-IL', label: '🇮🇱 Hebrew' },
  { value: 'id-ID', label: '🇮🇩 Indonesian' },
  { value: 'ms-MY', label: '🇲🇾 Malay' },
  { value: 'th-TH', label: '🇹🇭 Thai' },
  { value: 'vi-VN', label: '🇻🇳 Vietnamese' },
  { value: 'cs-CZ', label: '🇨🇿 Czech' },
  { value: 'ro-RO', label: '🇷🇴 Romanian' },
  { value: 'hu-HU', label: '🇭🇺 Hungarian' },
  { value: 'uk-UA', label: '🇺🇦 Ukrainian' },
  { value: 'hr-HR', label: '🇭🇷 Croatian' },
  { value: 'ca-ES', label: '🇪🇸 Catalan' },
  { value: 'fa-IR', label: '🇮🇷 Persian' },
  { value: 'sr-SP', label: '🇷🇸 Serbian' },
  { value: 'sk-SK', label: '🇸🇰 Slovak' },
  { value: 'bn', label: '🇧🇩 Bengali' },
  { value: 'ta-IN', label: '🇮🇳 Tamil' },
  { value: 'te-IN', label: '🇮🇳 Telugu' },
  { value: 'mr-IN', label: '🇮🇳 Marathi' },
  { value: 'ur-PK', label: '🇵🇰 Urdu' },
  { value: 'gu-IN', label: '🇮🇳 Gujarati' },
  { value: 'kn-IN', label: '🇮🇳 Kannada' },
  { value: 'ml', label: '🇮🇳 Malayalam' },
  { value: 'si', label: '🇱🇰 Sinhala' },
  { value: 'my', label: '🇲🇲 Burmese' },
  { value: 'km', label: '🇰🇭 Khmer' },
  { value: 'lo', label: '🇱🇦 Lao' },
  { value: 'ne', label: '🇳🇵 Nepali' },
  { value: 'mn-MN', label: '🇲🇳 Mongolian' },
  { value: 'ka-GE', label: '🇬🇪 Georgian' },
  { value: 'am', label: '🇪🇹 Amharic' },
  { value: 'sw-KE', label: '🇹🇿 Swahili' },
  { value: 'zu', label: '🇿🇦 Zulu' },
  { value: 'af-ZA', label: '🇿🇦 Afrikaans' },
  { value: 'az-AZ', label: '🇦🇿 Azerbaijani' },
  { value: 'eu-ES', label: '🇪🇸 Basque' },
  { value: 'be-BY', label: '🇧🇾 Belarusian' },
  { value: 'bs', label: '🇧🇦 Bosnian' },
  { value: 'bg-BG', label: '🇧🇬 Bulgarian' },
  { value: 'cy', label: '🇬🇧 Welsh' },
  { value: 'et-EE', label: '🇪🇪 Estonian' },
  { value: 'ga', label: '🇮🇪 Irish' },
  { value: 'gl-ES', label: '🇪🇸 Galician' },
  { value: 'ha', label: '🇳🇬 Hausa' },
  { value: 'haw', label: '🇺🇸 Hawaiian' },
  { value: 'is-IS', label: '🇮🇸 Icelandic' },
  { value: 'ig', label: '🇳🇬 Igbo' },
  { value: 'yi', label: '🇮🇱 Yiddish' },
  { value: 'jv', label: '🇮🇩 Javanese' },
  { value: 'kk-KZ', label: '🇰🇿 Kazakh' },
  { value: 'ku', label: '🇹🇷 Kurdish' },
  { value: 'ky-KZ', label: '🇰🇬 Kyrgyz' },
  { value: 'lv-LV', label: '🇱🇻 Latvian' },
  { value: 'lt-LT', label: '🇱🇹 Lithuanian' },
  { value: 'lb', label: '🇱🇺 Luxembourgish' },
  { value: 'mk-MK', label: '🇲🇰 Macedonian' },
  { value: 'mg', label: '🇲🇬 Malagasy' },
  { value: 'mt', label: '🇲🇹 Maltese' },
  { value: 'mi', label: '🇳🇿 Maori' },
  { value: 'mrj', label: '🇷🇺 Mari' },
  { value: 'no', label: '🇳🇴 Norwegian' },
  { value: 'or', label: '🇮🇳 Oriya' },
  { value: 'ps', label: '🇦🇫 Pashto' },
  { value: 'pa-IN', label: '🇮🇳 Punjabi' },
  { value: 'qu', label: '🇵🇪 Quechua' },
  { value: 'rw', label: '🇷🇼 Kinyarwanda' },
  { value: 'sa-IN', label: '🇮🇳 Sanskrit' },
  { value: 'sc', label: '🇮🇹 Sardinian' },
  { value: 'sn', label: '🇿🇼 Shona' },
  { value: 'sd', label: '🇵🇰 Sindhi' },
  { value: 'so', label: '🇸🇴 Somali' },
  { value: 'su', label: '🇮🇩 Sundanese' },
  { value: 'tg', label: '🇹🇯 Tajik' },
  { value: 'tt-RU', label: '🇷🇺 Tatar' },
  { value: 'ti', label: '🇪🇷 Tigrinya' },
  { value: 'tk', label: '🇹🇲 Turkmen' },
  { value: 'udm', label: '🇷🇺 Udmurt' },
  { value: 'ug', label: '🇨🇳 Uyghur' },
  { value: 'uz-UZ', label: '🇺🇿 Uzbek' },
  { value: 'wa', label: '🇧🇪 Walloon' },
  { value: 'xh', label: '🇿🇦 Xhosa' },
  { value: 'yo', label: '🇳🇬 Yoruba' },
  { value: 'za', label: '🇨🇳 Zhuang' },
].sort((a, b) => a.label.localeCompare(b.label));
