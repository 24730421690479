import {
  ActionIcon,
  Box,
  Button,
  Container,
  Divider,
  Group,
  Paper,
  PasswordInput,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ClientApplication } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ExternalProvider {
  internalId: string;
  clientId: string;
  providerType: 'google' | 'apple';
}

interface FormData {
  name: string;
  description: string;
  secret: string;
  externalProviders: ExternalProvider[];
}

const ClientApplicationForm: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Initial empty form state
  const initialFormState: FormData = {
    name: '',
    description: '',
    secret: '',
    externalProviders: [],
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);

  const handleChange =
    (field: keyof FormData) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
    };

  const addExternalProvider = (): void => {
    setFormData({
      ...formData,
      externalProviders: [
        ...formData.externalProviders,
        {
          internalId: '',
          clientId: '',
          providerType: 'google',
        },
      ],
    });
  };

  const updateProvider = (index: number, field: keyof ExternalProvider, value: string): void => {
    const updatedProviders = [...formData.externalProviders];
    updatedProviders[index] = {
      ...updatedProviders[index],
      [field]: value,
    };

    setFormData({
      ...formData,
      externalProviders: updatedProviders,
    });
  };

  const removeProvider = (index: number): void => {
    const updatedProviders = formData.externalProviders.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      externalProviders: updatedProviders,
    });
  };

  const handleClear = (): void => {
    setFormData(initialFormState);
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setLoading(true);

    const resource: ClientApplication = {
      extension: formData.externalProviders.map((provider) => ({
        url: 'https://api.ovok.com/fhir/StructureDefinition/external-auth-internal-id',
        valueString: provider.internalId,
        extension: [
          {
            url: 'https://api.ovok.com/fhir/StructureDefinition/external-auth-client-id',
            valueString: provider.clientId,
          },
          {
            url: 'https://api.ovok.com/fhir/StructureDefinition/external-auth-provider-type',
            valueString: provider.providerType,
          },
        ],
      })),
      resourceType: 'ClientApplication',
      name: formData.name,
      secret: formData.secret,
      description: formData.description || undefined,
    };

    medplum
      .createResource(resource)
      .then(() => {
        notifications.show({
          title: 'Success',
          message: `Client Application "${formData.name}" has been created successfully`,
          color: 'green',
          icon: <IconCheck size={16} />,
          autoClose: 3000,
        });
        navigate('/ClientApplication');
      })
      .catch((error) => {
        console.error('Error creating Client Application:', error);

        notifications.show({
          title: 'Error',
          message: `Failed to create Client Application: ${error.message || 'Unknown error'}`,
          color: 'red',
          icon: <IconX size={16} />,
          autoClose: 5000,
        });

        setLoading(false);
      });
  };

  return (
    <Container size="md" p="xl">
      <Paper shadow="md" p="lg" radius="md">
        <Title order={2} mb="xl">
          {'Add External Providers (Client Application)'}
        </Title>

        <form onSubmit={handleSubmit}>
          <Stack gap={16}>
            <TextInput
              required
              label="Name"
              placeholder="Enter client application name"
              value={formData.name}
              onChange={handleChange('name')}
            />

            <Textarea
              label="Description"
              placeholder="Enter description"
              minRows={3}
              value={formData.description}
              onChange={(event) => setFormData({ ...formData, description: event.currentTarget.value })}
            />

            <PasswordInput
              required
              label="Secret"
              placeholder="Enter secret"
              value={formData.secret}
              onChange={handleChange('secret')}
            />

            <Box mt="lg">
              <Text fw={500} size="md" mb="md">
                External Providers
              </Text>
              <Divider mb="md" />

              {formData.externalProviders.map((provider, index) => (
                <Box key={index} p="sm" mb="sm" style={{ border: '1px solid #e9ecef', borderRadius: '0.25rem' }}>
                  <Group justify="space-between" mb="xs">
                    <Text fw={500}>Provider #{index + 1}</Text>
                    <ActionIcon color="red" onClick={() => removeProvider(index)}>
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>

                  <Stack gap={16}>
                    <TextInput
                      required
                      label="Internal ID"
                      placeholder="Any value"
                      description="Add Internal ID"
                      value={provider.internalId}
                      onChange={(e) => updateProvider(index, 'internalId', e.currentTarget.value)}
                    />

                    <TextInput
                      required
                      label="Client ID"
                      placeholder="Any value"
                      description="Add Client ID"
                      value={provider.clientId}
                      onChange={(e) => updateProvider(index, 'clientId', e.currentTarget.value)}
                    />

                    <Select
                      required
                      searchable
                      label="Provider Type"
                      description="Add Provider Type"
                      value={provider.providerType}
                      onChange={(value) => updateProvider(index, 'providerType', value as 'google' | 'apple')}
                      data={[
                        { value: 'google', label: 'Google' },
                        { value: 'apple', label: 'Apple' },
                      ]}
                    />
                  </Stack>
                </Box>
              ))}

              <Button
                leftSection={<IconPlus size={16} />}
                variant="outline"
                onClick={addExternalProvider}
                fullWidth
                mt="md"
              >
                Add External Provider
              </Button>
            </Box>

            <Group justify="flex-end" mt="xl">
              <Button type="button" variant="outline" disabled={loading} onClick={handleClear}>
                Clear
              </Button>
              <Button type="submit" loading={loading}>
                Create Client Application
              </Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default ClientApplicationForm;
