import { Button, Group, JsonInput, Modal, Stack, Text, Textarea, TextInput, Paper } from '@mantine/core';
import React from 'react';
import { LanguageFlag } from './Flags';
import { LanguageCode } from './Interfaces';
import { IconUpload } from '@tabler/icons-react';

interface EditModalProps {
  opened: boolean;
  onClose: () => void;
  isSourceLanguage: boolean;
  editingTranslation: {
    key: string;
    value: string;
    sourceValue?: string;
    sourceLanguage?: LanguageCode;
    targetLanguage?: LanguageCode;
  } | null;
  onUpdateTranslation: () => void;
  onEditValueChange: (value: string) => void;
}

interface AddModalProps {
  opened: boolean;
  onClose: () => void;
  newTranslation: {
    key: string;
    value: string;
  };
  targetLanguage: LanguageCode;
  onAddTranslation: () => void;
  onNewTranslationChange: (field: 'key' | 'value', value: string) => void;
}

interface ImportJsonModalProps {
  opened: boolean;
  onClose: () => void;
  onImportJson: (json: string) => void;
}

export const EditTranslationModal: React.FC<EditModalProps> = ({
  opened,
  onClose,
  isSourceLanguage,
  editingTranslation,
  onUpdateTranslation,
  onEditValueChange,
}) => {
  if (isSourceLanguage) {
    return (
      <Modal opened={opened} onClose={onClose} title="Edit Source Translation" size="xl">
        <Stack gap="lg">
          <TextInput
            label="Translation Key"
            value={editingTranslation?.key || ''}
            readOnly
            disabled
            styles={{ input: { fontSize: '16px' } }}
          />
          <Textarea
            label="Translation Value"
            value={editingTranslation?.value || ''}
            onChange={(e) => onEditValueChange(e.target.value)}
            minRows={10}
            autosize
            styles={{
              input: {
                fontSize: '16px',
                lineHeight: '1.5',
              },
            }}
          />
          <Group justify="right" mt="md">
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!editingTranslation?.value} onClick={onUpdateTranslation}>
              Save Translation
            </Button>
          </Group>
        </Stack>
      </Modal>
    );
  }

  return (
    <Modal opened={opened} onClose={onClose} title="Edit Translation" size="xl">
      <Stack gap="lg">
        <Text size="lg" fw={500}>
          Key: {editingTranslation?.key}
        </Text>
        <Group grow align="flex-start" gap="xl">
          <Stack gap="md" style={{ flex: 1 }}>
            <Group>
              <LanguageFlag language={'en-US' as LanguageCode} />
            </Group>
            <Textarea
              value={editingTranslation?.sourceValue || ''}
              readOnly
              minRows={12}
              autosize
              styles={{
                input: {
                  fontSize: '16px',
                  lineHeight: '1.5',
                },
              }}
            />
          </Stack>

          <Stack gap="md" style={{ flex: 1 }}>
            <Group>
              <LanguageFlag language={editingTranslation?.targetLanguage as LanguageCode} />
            </Group>
            <Textarea
              value={editingTranslation?.value || ''}
              onChange={(e) => onEditValueChange(e.target.value)}
              autosize
              minRows={12}
              styles={{
                input: {
                  fontSize: '16px',
                  lineHeight: '1.5',
                },
              }}
            />
          </Stack>
        </Group>
        <Group justify="right" mt="md">
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onUpdateTranslation}>Save Translation</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export const AddTranslationModal: React.FC<AddModalProps> = ({
  opened,
  onClose,
  newTranslation,
  targetLanguage,
  onAddTranslation,
  onNewTranslationChange,
}) => {
  const isSourceLanguage = targetLanguage === 'en-US';

  if (isSourceLanguage) {
    return (
      <Modal opened={opened} onClose={onClose} title="Add New Translation" size="xl">
        <Stack gap="lg">
          <TextInput
            label="Translation Key"
            placeholder="e.g., home.screen.button.submit"
            required
            value={newTranslation.key}
            onChange={(e) => onNewTranslationChange('key', e.target.value)}
            styles={{ input: { fontSize: '16px' } }}
            error={
              newTranslation.key &&
              (!newTranslation.key.includes('.') ||
                newTranslation.key.endsWith('.') ||
                newTranslation.key.split('.').some((part) => !part.trim()))
                ? 'Invalid key format. Key must contain at least one dot (.) and alphanumeric characters after each dot.'
                : null
            }
          />
          <Textarea
            label="Translation Value"
            placeholder="Enter translation value"
            autosize
            required
            value={newTranslation.value}
            onChange={(e) => onNewTranslationChange('value', e.target.value)}
            minRows={10}
            styles={{
              input: {
                fontSize: '16px',
                lineHeight: '1.5',
              },
            }}
          />
          <Group justify="right" mt="md">
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={onAddTranslation}
              disabled={
                !newTranslation.key ||
                !newTranslation.value ||
                !newTranslation.key.includes('.') ||
                newTranslation.key.endsWith('.') ||
                newTranslation.key.split('.').some((part) => !part.trim())
              }
            >
              Add Translation
            </Button>
          </Group>
        </Stack>
      </Modal>
    );
  }

  return (
    <Modal opened={opened} onClose={onClose} title="Add New Translation" size="xl">
      <Stack gap="lg">
        <TextInput
          label="Translation Key"
          placeholder="e.g., home.screen.button.submit"
          value={newTranslation.key}
          onChange={(e) => onNewTranslationChange('key', e.target.value)}
          styles={{ input: { fontSize: '16px' } }}
          error={
            newTranslation.key && !/^[a-z0-9]+(?:\.[a-z0-9]+)*$/.test(newTranslation.key)
              ? 'Invalid key format. Use lowercase letters, numbers, and dots (e.g., home.screen.about).'
              : null
          }
        />
        <Group grow align="flex-start" gap="xl">
          <Stack gap="md" style={{ flex: 1 }}>
            <Group>
              <LanguageFlag language={'en-US' as LanguageCode} />
            </Group>
            <Textarea
              placeholder="Enter source text in English"
              value={newTranslation.value}
              onChange={(e) => onNewTranslationChange('value', e.target.value)}
              minRows={15}
              styles={{
                input: {
                  fontSize: '16px',
                  lineHeight: '1.5',
                },
              }}
            />
          </Stack>
          <Stack gap="md" style={{ flex: 1 }}>
            <Group>
              <LanguageFlag language={targetLanguage} />
            </Group>
            <Textarea
              placeholder="Enter translation"
              disabled
              minRows={15}
              styles={{
                input: {
                  fontSize: '16px',
                  lineHeight: '1.5',
                },
              }}
            />
          </Stack>
        </Group>
        <Group justify="right" mt="md">
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={onAddTranslation}
            disabled={!newTranslation.key || !newTranslation.value || !newTranslation.key.includes('.')}
          >
            Add Translation
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export const ImportJsonModal: React.FC<ImportJsonModalProps> = ({ opened, onClose, onImportJson }): JSX.Element => {
  const [jsonInput, setJsonInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [isDragging, setIsDragging] = React.useState(false);

  const handleImport = (): void => {
    try {
      // Validate JSON format
      JSON.parse(jsonInput);
      onImportJson(jsonInput);
    } catch (error) {
      setError('Invalid JSON format');
      console.error('Invalid JSON format:', error);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const content = event.target?.result as string;
          JSON.parse(content); // Validate JSON
          setJsonInput(content);
          setError('');
        } catch (error) {
          setError('Invalid JSON format in file');
          console.error('Invalid JSON format in file:', error);
        }
      };
      reader.readAsText(file);
    } else {
      setError('Please drop a valid JSON file');
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Import Translations from JSON" size="xl">
      <Stack>
        <Text size="sm" c="dimmed">
          Paste your i18n JSON content below or drag and drop a JSON file. The JSON should be in the format of key-value
          pairs.
        </Text>
        <Paper
          p="xl"
          radius="md"
          style={{
            border: `2px dashed ${isDragging ? 'var(--mantine-color-blue-6)' : 'var(--mantine-color-gray-4)'}`,
            backgroundColor: isDragging ? 'var(--mantine-color-blue-0)' : 'var(--mantine-color-gray-0)',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Stack align="center" gap="xs">
            <IconUpload size={32} color="var(--mantine-color-gray-6)" />
            <Text size="sm" c="dimmed">
              Drag and drop a JSON file here, or click to select
            </Text>
          </Stack>
        </Paper>
        <JsonInput
          label="JSON Content"
          placeholder="Paste your JSON here..."
          value={jsonInput}
          onChange={setJsonInput}
          minRows={15}
          rows={15}
          autosize
          formatOnBlur
          deserialize={JSON.parse}
        />
        {error && <Text c="red">{error}</Text>}
        <Group justify="right" mt="md">
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleImport}>Import</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
