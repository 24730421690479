import { AppShell, Container } from '@mantine/core';
import { useState } from 'react';
import { LanguageTable } from './components/localization/LanguageTable';
import TranslationTable from './components/localization/TranslationTable';
import { LanguageCode } from './components/localization/Interfaces';

export default function LocalizationPage(): JSX.Element {
  const [view, setView] = useState<'languages' | 'translations'>('languages');
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<LanguageCode | null>(null);

  const handleSelectLanguage = (code: LanguageCode): void => {
    setSelectedLanguageCode(code);
    setView('translations');
  };

  return (
    <AppShell>
      <Container size="xl" py="md">
        {view === 'languages' ? (
          <LanguageTable handleSelectLanguage={handleSelectLanguage} />
        ) : (
          selectedLanguageCode && <TranslationTable selectedLanguageCode={selectedLanguageCode} setView={setView} />
        )}
      </Container>
    </AppShell>
  );
}
