import {
  ActionIcon,
  Button,
  Container,
  Group,
  Loader,
  LoadingOverlay,
  Menu,
  Pagination,
  Paper,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  IconChevronLeft,
  IconDots,
  IconEdit,
  IconSearch,
  IconTrash,
  IconUpload,
  IconDownload,
} from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useOvok } from '../../utils/ovokclient';
import { LanguageFlag } from './Flags';
import { LanguageCode } from './Interfaces';
import { AddTranslationModal, EditTranslationModal, ImportJsonModal } from './TranslationModals';
import classes from './TranslationTable.module.css';

interface Author {
  reference: string;
  display: string;
}

interface TranslationItem {
  error: string;
  id: string;
  key: string;
  language: string;
  value: string;
  author: Author;
  date: string;
}

interface TranslationMapItem {
  source: TranslationItem;
  target: TranslationItem;
}

interface TranslationMapResponse {
  total: number;
  resources: TranslationMapItem[];
}

interface TranslationResponse {
  total: number;
  resources: TranslationItem[];
}

interface TranslationTableData {
  key: string;
  language: string;
  translatedValue: string;
  sourceValue?: string;
  author?: Author;
}

interface EditingState {
  key: string;
  value: string;
  sourceValue?: string;
  targetLanguage: LanguageCode;
  sourceLanguage: LanguageCode;
}

interface TranslationTableProps {
  selectedLanguageCode: LanguageCode;
  setView: React.Dispatch<React.SetStateAction<'languages' | 'translations'>>;
}

const ITEMS_PER_PAGE = 20;

const TranslationTable: React.FC<TranslationTableProps> = ({ selectedLanguageCode, setView }): JSX.Element => {
  const ovok = useOvok();

  const [translations, setTranslations] = useState<TranslationTableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingTranslation, setEditingTranslation] = useState<EditingState | null>(null);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
  const [newKeyModalOpened, { open: openNewKeyModal, close: closeNewKeyModal }] = useDisclosure(false);
  const [importModalOpened, { open: openImportModal, close: closeImportModal }] = useDisclosure(false);
  const [newTranslation, setNewTranslation] = useState({ key: '', value: '' });

  const fetchTranslations = useCallback(
    async (searchKey?: string, offset = 0): Promise<void> => {
      setLoading(true);
      try {
        const params: Record<string, string> = {
          _offset: offset.toString(),
          _count: ITEMS_PER_PAGE.toString(),
        };

        if (searchKey) {
          params['key:contains'] = searchKey;
        }

        let response;
        if (selectedLanguageCode === 'en-US') {
          params['languages'] = 'en-US';
          response = await ovok.get<TranslationResponse>('/localization', { params });
          // Transform the response to match our Translation interface
          const transformedTranslations = (response.data.resources as TranslationItem[]).map((item) => ({
            key: item.key,
            language: item.language,
            translatedValue: item.value,
            sourceValue: item.value,
            author: item.author,
          }));
          setTranslations(transformedTranslations);
        } else {
          response = await ovok.get<TranslationMapResponse>(`/localization/map/${selectedLanguageCode}`, { params });
          // Transform the response to match our Translation interface
          const transformedTranslations = (response.data.resources as TranslationMapItem[]).map((item) => ({
            key: item.source.key,
            language: item.target.language,
            translatedValue: item.target.value,
            author: item.target.author,
            sourceValue: item.source.value,
          }));
          setTranslations(transformedTranslations);
        }

        setTotalItems(response.data.total);
      } catch (error) {
        console.error('Error fetching translations:', error);
      } finally {
        setLoading(false);
      }
    },
    [selectedLanguageCode, ovok]
  );

  useEffect(() => {
    const offset = (page - 1) * ITEMS_PER_PAGE;
    fetchTranslations(searchQuery, offset).catch((error) => {
      console.error('Error in fetchTranslations effect:', error);
    });
  }, [page, searchQuery, fetchTranslations]);

  const handleSearch = (value: string): void => {
    setSearchQuery(value);
    setPage(1);
  };

  const handleEditClick = (translation: TranslationTableData): void => {
    if (!translation) {
      return;
    }

    setEditingTranslation({
      key: translation.key || '',
      value: translation.translatedValue || '',
      sourceValue: selectedLanguageCode === 'en-US' ? undefined : translation.sourceValue || '',
      targetLanguage: selectedLanguageCode as LanguageCode,
      sourceLanguage: 'en-US' as LanguageCode,
    });
    openEditModal();
  };

  const handleUpdateTranslation = async (): Promise<void> => {
    if (!editingTranslation) {
      return;
    }

    setLoading(true);
    try {
      const response = await ovok.put<TranslationItem>(
        `/localization/${selectedLanguageCode}/${editingTranslation.key}`,
        {
          value: editingTranslation.value,
        }
      );

      if (response?.data) {
        const offset = (page - 1) * ITEMS_PER_PAGE;
        await fetchTranslations(searchQuery, offset);

        notifications.show({
          title: 'Success',
          message: 'Translation updated successfully',
          color: 'green',
        });

        closeEditModal();
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating translation:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update translation. Please try again.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddTranslation = async (): Promise<void> => {
    if (!newTranslation.key || !newTranslation.value) {
      return;
    }

    setLoading(true);
    try {
      const response = await ovok.put<TranslationItem>(`/localization/${selectedLanguageCode}/${newTranslation.key}`, {
        value: newTranslation.value,
      });

      if (!response?.data) {
        throw new Error('Invalid response from server');
      }
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      // Only proceed with success actions if we have valid data
      const offset = (page - 1) * ITEMS_PER_PAGE;
      await fetchTranslations(searchQuery, offset);

      notifications.show({
        title: 'Success',
        message: 'Translation added successfully',
        color: 'green',
      });

      closeNewKeyModal();
      setNewTranslation({ key: '', value: '' });
    } catch (error: any) {
      console.error('Error adding translation:', error);
      // Check for the specific error response structure
      if (error.response?.data?.statusCode === 422) {
        notifications.show({
          title: 'Error',
          message: 'Not a valid key. Please check the key format and try again.',
          color: 'red',
        });
      } else {
        notifications.show({
          title: 'Error',
          message: 'Failed to add translation. Please try again.',
          color: 'red',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNewTranslationChange = (field: 'key' | 'value', value: string): void => {
    setNewTranslation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditValueChange = (value: string): void => {
    setEditingTranslation((prev) => (prev ? { ...prev, value } : null));
  };

  const handleImportJson = async (jsonString: string): Promise<void> => {
    try {
      setLoading(true);
      const translations = JSON.parse(jsonString);

      const response = await ovok.patch(`/localization/i18next/${selectedLanguageCode}`, translations);

      if (response.status === 200 || response.status === 202) {
        setTimeout(async () => {
          setPage(1);
          setSearchQuery('');

          await fetchTranslations('', 0);

          closeImportModal();

          setLoading(false);
          notifications.show({
            title: 'Success',
            message: 'Translations imported successfully.',
            color: 'green',
          });
        }, 2000);
      } else {
        console.error('Unexpected response:', response);
        throw new Error(`Unexpected response from server, status: ${response.status}`);
      }
    } catch (error: any) {
      console.error('Error importing translations:', error);
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to import translations.',
        color: 'red',
      });
    }
  };

  const handleDeleteTranslation = async (key: string): Promise<void> => {
    if (!confirm('Are you sure you want to delete this translation key? This action cannot be undone.')) {
      return;
    }

    setLoading(true);
    try {
      const response = await ovok.delete(`/localization/${selectedLanguageCode}/${key}`);
      if (response.status === 200) {
        setTranslations((prev) => prev.filter((translation) => translation.key !== key));
        setTotalItems((prev) => prev - 1);

        notifications.show({
          title: 'Success',
          message: 'Translation key deleted successfully',
          color: 'green',
        });
      } else {
        throw new Error('Failed to delete translation key');
      }
    } catch (error) {
      console.error('Error deleting translation:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete translation key. Please try again.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportJson = async (): Promise<void> => {
    setExportLoading(true);
    try {
      const response = await ovok.get(`/localization/i18next/${selectedLanguageCode}`);

      if (!response?.data) {
        throw new Error('Invalid response from server');
      }

      // Create a blob from the JSON data
      const blob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `translations-${selectedLanguageCode}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      notifications.show({
        title: 'Success',
        message: 'Translations exported successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error exporting translations:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to export translations. Please try again.',
        color: 'red',
      });
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <Container fluid p="md">
      <Paper shadow="sm" p="lg" radius="md" withBorder pb="xl">
        <LoadingOverlay visible={loading || exportLoading} />
        {totalItems === 0 && (
          <Text c="dimmed" mb="md">
            If you have already uploaded the translation keys, please wait up to one minute for the translations to be
            processed automatically, then reload the page.
          </Text>
        )}
        <Group justify="space-between" mb="xl">
          <Group>
            <ActionIcon size="lg" variant="subtle" onClick={() => setView('languages')}>
              <IconChevronLeft size={20} />
            </ActionIcon>
            <Title order={2}>{<LanguageFlag language={selectedLanguageCode as LanguageCode} />}</Title>
          </Group>
          <Group>
            <TextInput
              placeholder="Search by key..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              rightSection={<IconSearch size={16} />}
            />
            <Button leftSection={<IconUpload size={16} />} onClick={openImportModal}>
              Import JSON
            </Button>
            <Button leftSection={<IconDownload size={16} />} onClick={handleExportJson}>
              Export JSON
            </Button>
            {selectedLanguageCode === 'en-US' && <Button onClick={openNewKeyModal}>Add new translation</Button>}
          </Group>
        </Group>

        <div style={{ position: 'relative' }}>
          <Table
            striped
            highlightOnHover
            classNames={{
              table: classes.root,
              th: classes.header,
              td: classes.cell,
              tr: classes.row,
            }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '25%' }}>Key</Table.Th>
                <Table.Th style={{ width: '25%' }}>Source</Table.Th>
                {selectedLanguageCode !== 'en-US' && <Table.Th style={{ width: '25%' }}>Translation</Table.Th>}
                <Table.Th style={{ width: '15%' }}>Author</Table.Th>
                <Table.Th style={{ width: '10%' }}></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading ? (
                <Table.Tr>
                  <Table.Td colSpan={5} style={{ textAlign: 'center', padding: '2rem' }}>
                    <Loader size="sm" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                translations.map((translation) => (
                  <Table.Tr
                    key={translation.key}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEditClick(translation)}
                  >
                    <Table.Td>{translation.key}</Table.Td>
                    <Table.Td>{translation.sourceValue}</Table.Td>
                    {selectedLanguageCode !== 'en-US' && <Table.Td>{translation.translatedValue}</Table.Td>}
                    <Table.Td>{translation.author?.display || '-'}</Table.Td>
                    <Table.Td>
                      <Menu position="bottom-end">
                        <Menu.Target>
                          <ActionIcon onClick={(e) => e.stopPropagation()}>
                            <IconDots size={16} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(translation);
                            }}
                            leftSection={<IconEdit size={16} />}
                          >
                            Edit
                          </Menu.Item>
                          {selectedLanguageCode === 'en-US' && (
                            <Menu.Item
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteTranslation(translation.key).catch((error) => {
                                  console.error('Error in handleDeleteTranslation:', error);
                                });
                              }}
                              leftSection={<IconTrash size={16} />}
                              color="red"
                            >
                              Delete
                            </Menu.Item>
                          )}
                        </Menu.Dropdown>
                      </Menu>
                    </Table.Td>
                  </Table.Tr>
                ))
              )}
            </Table.Tbody>
          </Table>

          <Group justify="space-between" mt="xl">
            <Text c="dimmed">
              Showing {(page - 1) * ITEMS_PER_PAGE + translations.length} of {totalItems} items
            </Text>
            <Pagination total={Math.ceil(totalItems / ITEMS_PER_PAGE)} value={page} onChange={setPage} />
          </Group>
        </div>
      </Paper>

      <EditTranslationModal
        opened={editModalOpened}
        onClose={closeEditModal}
        isSourceLanguage={selectedLanguageCode === 'en-US'}
        editingTranslation={editingTranslation}
        onUpdateTranslation={handleUpdateTranslation}
        onEditValueChange={handleEditValueChange}
      />

      <AddTranslationModal
        opened={newKeyModalOpened}
        onClose={closeNewKeyModal}
        newTranslation={newTranslation}
        onAddTranslation={handleAddTranslation}
        onNewTranslationChange={handleNewTranslationChange}
        targetLanguage={selectedLanguageCode as LanguageCode}
      />

      <ImportJsonModal opened={importModalOpened} onClose={closeImportModal} onImportJson={handleImportJson} />
    </Container>
  );
};

export default TranslationTable;
